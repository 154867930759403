<template>
 <div class="relative position-relative" :class="{ 'lang-en': locate === 'en', 'lang-jp': locate === 'jp' }">
  <router-view>
  </router-view>
  <Alert />
  <notifications group="notifyAlert" position="top center" :animation="animation" class="custom-notify">
     <template #body="props">
       <!-- Danger Alert -->
       <div class="alert alert-danger alert-border-left alert-dismissible fade show d-flex align-items-center" role="alert">
         <i class="ri-error-warning-line me-3 align-middle" style="font-size: 25px"></i>
         <div>
           <div style="font-size: 13px">{{props.item.title}}</div>
           <div v-html="props.item.text"></div>
         </div>
         <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
       </div>

     </template>
   </notifications>

 </div>
</template>

<script>
import Alert from "@/components/Alert";
import {mapGetters} from "vuex";
import Swal from "sweetalert2";
import 'firebase/messaging';


export default {
  name: 'App',
  components: {
    Alert,
  },
  data() {
    return {}
  },
  computed: {
    locate() {
      return 'jp';
    },
    ...mapGetters({
      popupInfo: 'settings/getPopupInfo'
    }),

    animation () {
      return {
        /**
         * Animation function
         *
         * Runs before animating, so you can take the initial height, width, color, etc
         * @param  {HTMLElement}  element  The notification element
         */
        enter (element) {
          let height = element.clientHeight
          return {
            // animates from 0px to "height"
            height: [height, 0],

            // animates from 0 to random opacity (in range between 0.5 and 1)
            opacity: [Math.random() * 0.5 + 0.5, 0]
          }
        },
        leave: {
          height: 0,
          opacity: 0
        }
      }
    }
  },
  watch: {
    async popupInfo(value) {
      if (value.show) {
        await Swal.fire({
          title: "",
          text: value.message,
          icon: value.type,
          showConfirmButton: false,
          timer: 3000,
        });

        this.$store.commit('settings/SET_POPUP', {
          ...this.popupInfo,
          show: false,
        });
      }
    }
  },
  mounted() {
    this.$notify({
      title: "Important message",
      text: "Hello user!",
      leave: {
        height: 0,
        opacity: 0
      },
      type: 'danger',
      duration: -1,
    });
  }
}
</script>
<style lang="scss" scoped>

.custom-notify {
  width: 80% !important;
  left: calc(10%) !important;
  overflow: auto;
  max-height: 100vh;
}
@media (min-width: 768px) {
  .custom-notify {
    width: 30% !important;
    left: calc(35%) !important;
  }
}

</style>
