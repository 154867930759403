// import store from "@/state/store";

// Modules
import authRoutes from "./auth";
import accountRoutes from "./facility-account";
import errorRoutes from "./error";
import { ROLES } from "@/constants";

export default [
  ...authRoutes,
  ...accountRoutes,
  ...errorRoutes,
  {
    path: "/admin/setting/facility-info-management",
    name: "facility-info-management",
    meta: {
      title: "Facility Info Management",
      authRequired: true,
      roles: [ROLES.FACILITY_MANAGER, ROLES.FACILITY_ACCOUNT],
    },
    component: () => import("../views/setting/facility-management/index.vue"),
  },
  {
    path: "/admin/setting/user-management-by-admin",
    name: "user-management-by-admin",
    meta: {
      title: "User Management",
      authRequired: true,
      roles: [ROLES.ADMIN],
    },
    component: () =>
      import("../views/setting/user-management/admin-manager/index.vue"),
  },
  {
    path: "/admin/setting/user-management-by-admin/tablet",
    name: "user-management-by-admin-tablet",
    meta: {
      title: "User tablet Management",
      authRequired: true,
      roles: [ROLES.ADMIN],
    },
    component: () =>
        import("../views/setting/user-management/admin-manager/User-tablet.vue"),
  },
  {
    path: "/admin/setting/user-management-by-admin/create",
    name: "admin-create-user",
    meta: {
      title: "Create User By Admin",
      authRequired: true,
      roles: [ROLES.ADMIN],
    },
    component: () =>
      import("../views/setting/user-management/admin-manager/create/index.vue"),
  },
  {
    path: "/admin/setting/user-management-by-admin/:userId",
    name: "admin-update-user",
    meta: {
      title: "Update User By Admin",
      authRequired: true,
      roles: [ROLES.ADMIN],
    },
    component: () =>
      import("../views/setting/user-management/admin-manager/update/index.vue"),
  },
  {
    path: "/admin/setting/user-management-by-facility-manager/:userId",
    name: "facility-manager-update-user",
    meta: {
      title: "Update User By Facility Manager",
      authRequired: true,
      roles: [ROLES.FACILITY_MANAGER],
    },
    component: () =>
      import("../views/setting/facility-manager/update/index.vue"),
  },
  {
    path: "/admin/setting/user-management-by-facility-manager/create",
    name: "facility-manager-create-user",
    meta: {
      title: "Create User By Facility Manager",
      authRequired: true,
      roles: [ROLES.FACILITY_MANAGER],
    },
    component: () =>
      import("../views/setting/facility-manager/create/index.vue"),
  },
  {
    path: "/admin/setting/user-management-by-facility-manager",
    name: "facility-manager-user-list",
    meta: {
      title: "List user by facility manager",
      authRequired: true,
      roles: [ROLES.FACILITY_MANAGER],
    },
    component: () => import("../views/setting/facility-manager/index.vue"),
  },
  /*admin facility management*/
  {
    path: "/admin/facility/list",
    name: "admin-facility-list",
    meta: {
      title: "List facility",
      authRequired: true,
      roles: [ROLES.ADMIN],
    },
    //src/views/setting/user-management/admin-manager/facility-manager-list.vue
    component: () =>
      import("../views/setting/admin-management/facilities/index.vue"),
  },
  {
    path: "/admin/facility/:facilityId",
    name: "facility-update-management",
    meta: {
      title: "Facility Info Management",
      authRequired: true,
      roles: [ROLES.FACILITY_MANAGER, ROLES.ADMIN],
    },
    component: () =>
      import("../views/setting/admin-management/facilities/Update.vue"),
  },
  {
    path: "/admin/facility-app/:facilityId",
    name: "facility-update-app-management",
    meta: {
      title: "Facility Info Management",
      authRequired: true,
      roles: [ROLES.ADMIN],
    },
    component: () =>
        import("../views/setting/admin-management/tablet-facilities/Update.vue"),
  },
  {
    path: "/admin/facility/create",
    name: "facility-create-management",
    meta: {
      title: "Facility Info Management",
      authRequired: true,
      roles: [ROLES.FACILITY_MANAGER, ROLES.ADMIN],
    },
    component: () =>
      import("../views/setting/admin-management/facilities/Create.vue"),
  },

  /*advertising management*/
  {
    path: "/admin/setting/advertisement-management",
    name: "advertisement-management",
    meta: {
      title: "Advertising management",
      authRequired: true,
      roles: [ROLES.ADMIN],
    },
    //src/views/setting/user-management/admin-manager/facility-manager-list.vue
    component: () => import("../views/setting/ads-management/index.vue"),
  },
  /*advertising history*/
  {
    path: "/admin/setting/advertisement-history",
    name: "advertisement-history",
    meta: {
      title: "Advertising history",
      authRequired: true,
      roles: [ROLES.ADMIN],
    },
    //src/views/setting/user-management/admin-manager/facility-manager-list.vue
    component: () =>
      import("../views/setting/ads-management/history/index.vue"),
  },
  /*play discs*/
  {
    path: "/admin/play-discs",
    name: "play-discs-management",
    meta: {
      title: "Play dist management",
      authRequired: true,
      roles: [ROLES.ADMIN],
    },
    //src/views/setting/user-management/admin-manager/facility-manager-list.vue
    component: () =>
        import("../views/setting/admin-management/play-dist/index.vue"),
  },
  {
    path: "/admin/play-discs/create",
    name: "play-discs-create",
    meta: {
      title: "Play dist create",
      authRequired: true,
      roles: [ROLES.ADMIN],
    },
    component: () =>
        import("../views/setting/admin-management/play-dist/create.vue"),
  },
  {
    path: "/admin/play-discs/:playlistId",
    name: "play-discs-update",
    meta: {
      title: "Play dist update",
      authRequired: true,
      roles: [ROLES.ADMIN],
    },
    component: () =>
        import("../views/setting/admin-management/play-dist/create.vue"),
  },
  {
    path: "/admin/setting/upload-facility-list",
    name: "upload-facility-list",
    meta: {
      title: "Upload facility list",
      authRequired: true,
      roles: [ROLES.ADMIN],
    },
    //src/views/setting/user-management/admin-manager/facility-manager-list.vue
    component: () =>
      import(
        "../views/setting/admin-management/upload-facility-list/index.vue"
      ),
  },
  {
    path: "/admin/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
      roles: [ROLES.FACILITY_MANAGER, ROLES.FACILITY_ACCOUNT],
    },
    component: () => import("../views/dashboard/index.vue"),
  },

  {
    path: "/admin/setting/system-management",
    name: "system-management",
    meta: {
      title: "System management",
      authRequired: true,
      roles: [ROLES.ADMIN],
    },
    component: () => import("../views/setting/system-management/index.vue"),
  },
  {
    path: "/sns/:id",
    name: "sns",
    meta: {
      title: "SNS",
      authRequired: false,
    },
    component: () => import("../views/sns/public/index.vue"),
  },
  {
    path: "/admin/setting/sns",
    name: "sns-setting",
    meta: {
      title: "SNS Setting",
      authRequired: true,
      roles: [ROLES.FACILITY_MANAGER, ROLES.FACILITY_ACCOUNT],
    },
    component: () => import("../views/sns/setting/index.vue"),
  },
  {
    path: "/admin/setting/coupon",
    name: "coupon_list",
    meta: {
      title: "Coupon Setting",
      authRequired: true,
      roles: [ROLES.FACILITY_MANAGER, ROLES.FACILITY_ACCOUNT],
    },
    component: () => import("../views/coupon/index.vue"),
  },
  {
    path: "/admin/setting/coupon/:couponId",
    name: "coupon_update",
    meta: {
      title: "Coupon Update",
      authRequired: true,
      roles: [ROLES.FACILITY_MANAGER],
    },
    component: () => import("../views/coupon/update/index.vue"),
  },
  {
    path: "/admin/setting/coupon/create",
    name: "coupon_create",
    meta: {
      title: "Coupon Create",
      authRequired: true,
      roles: [ROLES.FACILITY_MANAGER],
    },
    component: () => import("../views/coupon/create/index.vue"),
  },
  {
    path: "/admin/setting/facilities/application",
    name: "facilities-management-by-admin",
    meta: {
      title: "User Management",
      authRequired: true,
      roles: [ROLES.ADMIN],
    },
    component: () =>
      import(
        "../views/setting/admin-management/tablet-facilities/index.vue"
      ),
  },
  {
    path: "/admin/setting/upload-images",
    name: "admin-upload-images",
    meta: {
      title: "Upload images",
      authRequired: true,
      roles: [ROLES.ADMIN],
    },
    component: () =>
      import("../views/setting/admin-management/upload-images/UploadImage.vue"),
  },
  {
    path: "/admin/staff-management/shift-form",
    name: "shift-form",
    meta: {
      title: "Shift Management",
      authRequired: true,
      roles: [ROLES.FACILITY_MANAGER, ROLES.FACILITY_ACCOUNT],
    },
    component: () => import("../views/timesheet/calendar.vue"),
  },
  {
    path: "/admin/staff-management/record-chart",
    name: "record-chart",
    meta: {
      title: "Record Chart",
      authRequired: true,
      roles: [ROLES.FACILITY_MANAGER, ROLES.FACILITY_ACCOUNT],
    },
    component: () => import("../views/timesheet/index.vue"),
  },
  {
    path: "/admin/setting/publicApi",
    name: "publicApi-management",
    meta: {
      title: "Public API",
      authRequired: true,
      roles: [ROLES.ADMIN],
    },
    component: () => import("../views/setting/public-api/index.vue"),
  },
  // push notification
  {
    path: "/admin/notification",
    name: "notification-management",
    meta: {
      title: "Notification management",
      authRequired: true,
      roles: [ROLES.ADMIN, ROLES.FACILITY_MANAGER, ROLES.FACILITY_ACCOUNT],
    },
    component: () => import("../views/setting/admin-management/push-notification/index.vue"),
  },
  // alert notify
  {
    path: "/admin/setting-alert",
    name: "setting-alert",
    meta: {
      title: "Setting alert",
      authRequired: true,
      roles: [ROLES.FACILITY_MANAGER, ROLES.FACILITY_ACCOUNT],
    },
    component: () => import("../views/setting/facility-management/alert-setting/index.vue"),
  },
  // holiday
  {
    path: "/admin/holidays",
    name: "setting-holidays",
    meta: {
      title: "Setting holidays",
      authRequired: true,
      roles: [ROLES.ADMIN],
    },
    component: () => import("../views/setting/holiday-management/index.vue"),
  }
];
