import axios from "axios";
import {getCookieToken, toBearerAuth} from "../header";
import {REQUEST_TIMEOUT} from "../../constants";
import router from "../../router";
import { STORAGE_KEY } from "@/constants";
import Cookies from 'js-cookie';

const detectOSToHeader = () => {
  const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
  const iosPlatforms = ["iPhone", "iPad", "iPod"];

  const androidRegex = /Android/;
  const linuxRegex = /Linux/;

  const platform = window.navigator.platform;
  const userAgent = window.navigator.userAgent;

  let system = "";

  let device = new Array(
    ["Microsoft Edge", /edg/i],
    ["Microsoft Internet Explorer", /trident/i],
    ["Mozilla Firefox", /firefox|fxios/i],
    ["Opera", /opr\//i],
    ["UC Browser", /ucbrowser/i],
    ["Samsung Browser", /samsungbrowser/i],
    ["Google Chrome", /chrome|chromium|crios/i],
    ["Apple Safari", /safari/i],
    ["Unknown", /.+/i]
  )
    .find(([, value]) => value.test(userAgent))
    .shift();

  if (macosPlatforms.indexOf(platform) !== -1) {
    system = "MacOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    system = "Window";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    system = "iOS";
  } else if (androidRegex.test(userAgent)) {
    system = "Android";
  } else if (linuxRegex.test(platform)) {
    system = "Linux";
  }

  return {
    system,
    device
  }
};

const getHeaders = (token = "") => {
  const defaultHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
    ...detectOSToHeader(),
    tz: new Date().getTimezoneOffset()
  };
  if (token) {
    return {
      ...defaultHeaders,
      Authorization: toBearerAuth(token),
    };
  }

  return defaultHeaders;
};

axios.defaults.baseURL = process.env.VUE_APP_SAUNA_URL;
axios.defaults.timeout = REQUEST_TIMEOUT;

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent -> set Authorization header from header
    //let token = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    const token = getCookieToken();
    if (token) {
      config.headers = getHeaders(token);
    } else {
      config.headers = getHeaders();
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    // transform from response.data of axios to response data from api
    return response.data;
  },
  (error) => {
    let response = error?.response || null;

    if (error.code === "ERR_NETWORK") {
      //  window.location.href = "/admin/error/offline";
    }

    if (response) {
      if (response.data.statusCode == 400
        && (response.data.message == "invalid signature" || response.data.message == "jwt expired")
      ) {
        // TODO: use common with nav-bar
        const rememberMe = localStorage.getItem(STORAGE_KEY.REMEMBER_USER) ? JSON.parse(localStorage.getItem(STORAGE_KEY.REMEMBER_USER)) : false;
        const remember_username = localStorage.getItem(STORAGE_KEY.REMEMBER_USERNAME);
        const remember_password = localStorage.getItem(STORAGE_KEY.REMEMBER_PASSWORD);
        const second = localStorage.getItem(STORAGE_KEY.AUTO_REFRESH);
        const refreshDashboard = localStorage.getItem('refresh');
        localStorage.clear();
        localStorage.setItem(STORAGE_KEY.REMEMBER_USER, rememberMe);
        localStorage.setItem(STORAGE_KEY.AUTO_REFRESH, second)
        localStorage.setItem('refresh', refreshDashboard);
        if (rememberMe) {
          localStorage.setItem(STORAGE_KEY.REMEMBER_USERNAME, remember_username);
          localStorage.setItem(STORAGE_KEY.REMEMBER_PASSWORD, remember_password);
        }
        Cookies.remove('token', {
          path: '/',
        })
        const domain = process.env.VUE_APP_ENV ? '' : '.tokyo-saunist.com'
        Cookies.remove('profile_twitter', {
          domain
        })

        router.push('/admin/auth/signin')
      }

      // if (response.status === 401) {
      //   window.location.href = "/admin/error/401";
      // }
      //
      // if (response.status === 403) {
      //   window.location.href = "/admin/error/403";
      // }
      //
      // if (response.status === 500) {
      //   //window.location.href = "/admin/error/500";
      // }


    }

    return Promise.reject(error);
  }
);

export default axios;
