import Cookies from "js-cookie"
import moment from "moment"
import { HEADER_KEY } from "../constants"

export const toBearerAuth = (token) => {
    return `${HEADER_KEY.BEARER} ${token}`
}

export const getCookieToken = (cookieName = 'token') => {
    let token = ''
    let cookie = document.cookie || '';
    let expiredTimeCookie = localStorage.getItem('expiredTimeCookie');
    if(expiredTimeCookie) {
      const isBefore = moment(expiredTimeCookie).isBefore(moment(new Date()))
      if(isBefore ) {
        Cookies.remove('token', {
          path: '/',
        })
        localStorage.removeItem('expiredTimeCookie');
        return ''
      } 
    }
    let items = cookie.split(';');
    for(const item of items) {
      if(item.includes(`${cookieName}=`)) {
        token = item.split('=')[1]
        break;
      }
    }

    return token
}