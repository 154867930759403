
export const state = {
  alert: {
    show: false,
    type: 'success',
    message: 'Success'
  },
  refreshData: false,
  seconds: 30,
  refreshHeader: false,
  facilityId: localStorage.getItem('currentFacilityId') || '',
  popup: {
    show: false,
    type: 'success',
    message: 'Success'
  },
  notify: [],
  fullscreen: false
}

export const mutations = {
  SET_ALERT(state, data) {
    state.alert = data;
  },
  SET_REFRESH_DATA(state, data) {
    state.refreshData = data;
  },
  SET_SECONDS(state, data) {
    state.seconds = data;
  },
  SET_FACILITY_ID(state, data) {
    state.facilityId = data
  },
  SET_POPUP(state, data) {
    state.popup = data;
  },
  SET_NOTIFY(state, data) {
    state.notify = data;
  },
  SET_REFRESH_HEADER(state, data) {
    state.refreshHeader = data;
  },
  RESET_FACILITY(state, data) {
    state.facilityId = data
    localStorage.removeItem('currentFacilityId')
  },
  SET_FULLSCREEN(state, data) {
    state.fullscreen = data;
  },
}

export const getters = {
  getAlertInfo: state => state.alert,
  getRefreshData: state => state.refreshData,
  getSeconds: state => state.seconds,
  getFacilityId: state => state.facilityId,
  getPopupInfo: state => state.popup,
  getNotify: state => state.notify,
  getRefreshHeader: state => state.refreshHeader,
  getFullScreen: state => state.fullscreen
}

export const actions = {
}
