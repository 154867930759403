import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import i18n from './i18n'
import store from "./state/store";
import BootstrapVue3 from 'bootstrap-vue-3';
import VueApexCharts from "vue3-apexcharts";
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import 'remixicon/fonts/remixicon.css'
import '@/assets/scss/config/saas/app.scss';
import Maska from 'maska';
import Notifications from '@kyvg/vue3-notification'

const app = createApp(App);

app.use(store)
    .use(router)
    .use(Notifications)
    .component('v-select', vSelect)
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .use(Maska)
    .use(i18n)
    .mount('#app')
